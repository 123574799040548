<template>
  <div class="Receipt">
    <div class="container">
      <h3>{{ entity.CompanyName }}</h3>
      <div class="cell"><span>单号:</span>{{ entity.Id }}</div>
      <div class="cell">
        <span>日期:</span
        >{{ entity.CreateTime ? entity.CreateTime.substring(0, 19) : "" }}
      </div>
      <div class="cell"><span>客户:</span>{{ entity.CustomerName }}</div>
      <div
        class="tableRow"
        style="border-bottom: 1px dashed #ccc; margin-top: 10px; line-height: 2"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">品名/编码</span>
        <span class="tableCol">数量</span>
        <!-- <span class="tableCol">单位</span> -->
        <span class="tableCol">单价</span>
        <span class="tableCol">小计</span>
      </div>
      <div style="padding: 10px 0">
        <div v-for="(item, index) in data" :key="index">
          <div class="tableRow">
            <span class="tableIndex">{{ index + 1 }}</span>
            <span class="tableCol left">{{ item.cInvName }}</span>
            <span class="tableCol">{{ item.Quantity }}</span>
            <span class="tableCol">{{
              item.iInvRCost ? Number(item.iInvRCost).toFixed(2) : ""
            }}</span>
            <span class="tableCol">{{
              item.SumiInvRCost ? Number(item.SumiInvRCost).toFixed(2) : ""
            }}</span>
          </div>
          <div class="tableRow">
            <span class="tableIndex"></span>
            <span class="tableCol left">{{ item.cInvCode }}</span>
            <span class="tableCol">{{ item.GroupName }}</span>
            <span class="tableCol col2">规格: {{ item.cInvStd }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 95%" class="fixBtn">
      <a v-if="href" :href="href" style="color: #fff">
        <el-button type="primary" style="width: 100%"> 去签收 </el-button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      entity: {},
      href: "",
    };
  },
  mounted() {
    this.$http
      .post("/BO/BillList/GetWebOpenLink?Key=" + this.$route.query.id)
      .then((res) => {
        console.log(res);
        if (res.Success) {
          this.href = res.Data.Openlink;
          this.getFormData(res.Data.BilllistId);
        }
      });
  },
  methods: {
    getFormData(id) {
      this.$http.post("/BO/BillList/GetBillDetail", { id }).then((res) => {
        if (res.Success) {
          this.entity = res.Data;
          let table = JSON.parse(res.Data.Content).printlist.filter(
            (item) => item.Keys == "table"
          );
          if (table.length > 0) {
            this.data = table[0].datalist;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fixBtn {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #fff;
}
.Receipt {
  //   display: flex;
  //   justify-content: center;
  position: relative;
  .container {
    margin: 0 auto;
    color: #000;
    min-width: 216px;
    max-width: 100%;
    padding: 10px;
    h3 {
      text-align: center;
    }
    .cell {
      display: flex;
      line-height: 2;
      span {
        width: 40px;
      }
    }
    .tableRow {
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        overflow: hidden;
      }
      .tableCol {
        text-align: right;
        width: 75px;
        overflow: hidden;
      }
      .left {
        text-align: left;
        flex: 1;
      }
      .tableIndex {
        width: 25px;
      }
      .col2 {
        width: 150px;
      }
    }
  }
}
</style>